.page-info {
  h2 {
    margin-top: 2em;
    margin-bottom: 1em;
  }
  .content {
    margin-bottom: 3em;
    p {
      font-size: 1.1em;
      line-height: 1.5em;
    }
  }
}

.missing-page {
  padding-bottom: 5em;
  h2 {
    color: $light-black;
  }
  .content {
    a {
      color: $text-black;
      padding-bottom: 0.5em;
      border-bottom: 2px solid $light-red;
    }
  }
}
