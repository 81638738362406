#signup,
#signin {
  padding: 5em 0;
  background-color: $light-grey;
  #signin-container {
    background-color: white;
    box-shadow: 0 2px 2px #D0D0D0;
    padding: 2em 3em 2em;
    img {
      width: 120px;
    }
    form {
      margin-top: 1em;
    }
    h3 {
      margin: 0.5 0 1em;
    }
    button {
      width: 50%;
      padding: 1em 0;
      border-radius: 0;
      border-color: transparent;
      margin-top: 2em;
      letter-spacing: 2px;
    }
  }
  .register {
    margin-top: 1em;
  }
}
