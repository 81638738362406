body {
  color: $black;
}

a {
  &:link, &:hover, &:visited, &:active {
    text-decoration: none;
  }
}

@mixin ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis {
  @include ellipsis();
}

.pill {
  background-color: #ABABAB;
  color: #FFF;
  padding: 0.2em 0.5em;
  border-radius: 2px;
  margin-right: 0.2em;
}

#loading {
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 100;
  width: 100%;
  height: 100%;
  align-items: center;
  svg {
    width: 4em;
    margin-bottom: 8em;
  }
}

.disable-number-spinner {
  input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    }
  }
}

.form-control {
  padding: 10px;
}

.breadcrumb-section {
  background-color: $light-grey;
  .breadcrumb {
    background-color: $light-grey;
    margin-bottom: 0;
    padding-left: 0;
  }
}
