$black: #4A4A4A;
$text-black: #2B3A41;
$light-black: #777777;

$dark-grey: #ABABAB;
$grey: #E2E2E2;
$medium-grey: #D0D0D0;
$light-grey: #F5F5F5;

$bright-red: #FF4646;
$light-red: #FF6666;
$red: #E41C24;

$green: #6AD48F;
$light-green: #8EE8AE;
$dark-green: #40AF7A;

$blue: #6C97F4;

$orange:  #ffb300;

$light-blue: #F5FFFC;
$dark-blue: #E1FAF2;
