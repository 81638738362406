@font-face {
    font-family: 'kanitblack';
    src: url('../fonts/kanit/kanit-black.woff2') format('woff2'),
         url('../fonts/kanit/kanit-black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kanitbold';
    src: url('../fonts/kanit/kanit-bold.woff2') format('woff2'),
         url('../fonts/kanit/kanit-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kanitextrabold';
    src: url('../fonts/kanit/kanit-extrabold.woff2') format('woff2'),
         url('../fonts/kanit/kanit-extrabold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kanitextralight';
    src: url('../fonts/kanit/kanit-extralight.woff2') format('woff2'),
         url('../fonts/kanit/kanit-extralight.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kanitlight';
    src: url('../fonts/kanit/kanit-light.woff2') format('woff2'),
         url('../fonts/kanit/kanit-light.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kanitmedium';
    src: url('../fonts/kanit/kanit-medium.woff2') format('woff2'),
         url('../fonts/kanit/kanit-medium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kanitregular';
    src: url('../fonts/kanit/kanit-regular.woff2') format('woff2'),
         url('../fonts/kanit/kanit-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kanitsemibold';
    src: url('../fonts/kanit/kanit-semibold.woff2') format('woff2'),
         url('../fonts/kanit/kanit-semibold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'kanitthin';
    src: url('../fonts/kanit/kanit-thin.woff2') format('woff2'),
         url('../fonts/kanit/kanit-thin.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
