.dropdown-menus {
  &:hover .dropdown-content {
    display: block;
  }
  .dropdown-content {
    z-index: 100;
    display: none;
    position: absolute;
    min-width: 250px;
    background: white;
    border: 1px solid $grey;
    margin-top: 1em;
    box-shadow: 0px 2px 2px $grey;
    border-radius: 4px;
    ul {
      margin: 0;
      line-height: 1.7em;
      li {
        margin: 0;
        display: block;
        &:last-child {
          margin-bottom: 0.5em;
        }
        hr {
          margin: 0.5em 0;
        }
        a {
          padding: 0.2em 1em;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 15px;
          > span {
            display: none;
            margin-bottom: 0.5em;
            color: $light-red;
          }
          &:hover {
            color: $light-red;
            font-weight: bold;
            > span {
              display: block;
            }
          }
        }
      }
    }
    .topic {
      color: #9B9B9B;
      font-size: 0.9em;
      margin: 0.8em 1.4em 0.2em;
    }
    .arrow-up-container {
      div {
        position: absolute;
        top: -15px;
        left: 20px;
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 15px solid white;
      }
      .bg {
        border-bottom-color: #777777;
      }
    }
  }
}
