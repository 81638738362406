.filter-container {

  #filter-toggle {
    font-size: 1.5em;
    position: absolute;
    top: 0;
    background-color: white;
    padding: 10px 15px;
    right: -25px;
    .glyphicon {
      // margin-right: 10px;
    }
  }
}

#show-categories-btn {
  display: none;
}

@media (max-width: $screen-xs-max) {
  .filter-container {
    position: absolute;
    z-index: 100;
    background: white;
    padding: 1em;
    padding-right: 5em;
    top: 0;
    left: -300px;
    box-shadow: 0 2px 2px #E2E2E2;
    transition: left 1s;
    top: 62px;
    &.opened {
      left: 0;
    }
  }

  .products-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
  }

  #show-categories-btn {
    display: flex;
    align-items: center;
    background: $light-red;
    padding: 0.5em 0.8em;
    border-radius: 4px;
    color: white;
    border-bottom: 2px solid $red;
    span {
      margin-right: 0.5em;
    }
  }
}

.filter {
  .filter-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: initial;
      &.hide {
        display: none;
      }
    }
    .hide-btn {
      display: none;
    }
  }
  // &.car-brands {
  //   ul {
  //     display: none;
  //   }
  // }
}
