footer {
  // margin-top: 4em;
  background-color: $black;
  color: white;
  .container {
    padding: 2em 0;
    img {
      width: 100%;
    }
  }
  .title {
    color: $light-black;
    font-weight: bold;
  }
  .pull-right {
    text-align: right;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin: 0.2em 0;
      a {
        color: $grey;
        &:hover {
          color: white;
        }
      }
      span {
        color: $light-black;
      }
    }
  }
  .mobile {
    .row {
      margin: 0;
    }
    ul {
      &.menu {
        li {
          display: inline-block;
          padding: 0 0.2em;
          margin-bottom: 1em;
          &:last-child {
            border: none;
          }
        }
      }
    }
    a img {
      width: 10%;
    }
  }
}
