#confirm-contact {
  .address-container {
    display: flex;
    justify-content: space-between;
  }
  .address-group {
    width: 32%;
  }
  .form-group {
    small {
      color: $light-red;
    }
  }
  form {
    margin-top: 2em;
    margin-bottom: 5em;
  }
  @media (max-width: $screen-xs-max) {
    padding-top: 2em;
    h2 {
      text-align: center;
    }
    .address-container {
      display: block;
    }
    .address-group {
      display: inline-block;
      width: 48%;
      &.country {
        display: block;
      }
      &.zip {
        float: right;
      }
    }
  }
  .user-info {
    margin: 3em 0;
    line-height: 2.5em;
    div > span:nth-child(2) {
      display: inline-block;
      width: 60%;
      text-align: left;
      background-color: $light-grey;
      margin-bottom: 0.5em;
      padding: 0 1em;
    }
    div > span:first-child {
      display: inline-block;
      width: 25%;
      text-align: left;
      color: $dark-grey;
    }
  }
  .signup {
    margin-bottom: 2em;
  }
  .info {
    margin: 1em 0;
    padding-left: 1em;
    color: $dark-grey;
  }
  .disabled {
    input,
    textarea,
    select {
      pointer-events: none;
      background: $light-grey;
      box-shadow: none;
    }
  }
}
