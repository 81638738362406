#products, #products-search {
  color: $black;
  padding-top: 2em;
  padding-bottom: 5em;
  background-color: $light-grey;

  .container {
    position: relative;
  }

  .product-list-container {
    padding: 0.5em;
    padding-top: 0;
    .no-results {
      font-size: 1.5em;
      text-align: center;
      color: $dark-grey;
    }
  }
  @media (min-width: $screen-md-min) {
    .product-list-container {
      width: 80%;
    }
  }
  .product-list {
    display: inline-block;
    margin-bottom: 1em;
    .product-container {
      padding: 0 0.5em;
      .product {
        background-color: white;
        box-shadow: 0 2px 2px $grey;
        border-radius: 4px;
        margin-bottom: 1.5em;
        border: 1px solid $grey;
        border-top: none;
        .image {
          position: relative;
          margin-bottom: 0.5em;
        }
        a {
          color: $light-red;
        }
        .id-car {
          font-weight: bold;
          padding: 0.5em 0.5em;
          .id {
            background: $black;
            padding: 0.1em 0.5em;
            border-radius: 4px;
            color: white;
          }
          .car {
            float: right;
            color: $blue;
          }
        }
        img {
          width: 100%;
          @media (max-width: $screen-xs-max) {
          }
          @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            max-height: 230px;
          }
          @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            max-height: 155px;
          }
          @media (min-width: $screen-lg-min) {
            max-height: 190px;
          }
        }
        .info {
          text-align: center;
          min-height: 165px;
          display: inline-block;
          width: 100%;
          padding: 0 0.5em;
          position: relative;
          .name {
            font-size: 1.4em;
            margin: 0.2em 0 0.5em;
          }
          .details {
            margin: 0.3em 0;
            color: $text-black;
          }
          .part-info {
            color: $dark-grey;
            border-top: 1px solid $grey;
            padding-top: 0.5em;
            position: absolute;
            width: 100%;
            bottom: 0em;
            left: 0;
            background-color: $light-grey;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-bottom: 0.5em;
            min-height: 90px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .number {
              font-size: 1.2em;
              color: $black;
            }
          }
        }
        &:hover {
          .order-mask {
            display: block;
          }
        }
        .order-mask {
          position: absolute;
          top: 0;
          height: 30%;
          background: rgba(0, 0, 0, 0.2);
          top: 50%;
          left: 50%;
          transform:  translate(-50%, -50%);
          display: none;
          .btn-order {
            background: $green;
            color: white;
            top: 50%;
            left: 50%;
            position: absolute;
            transform: translateX(-50%) translateY(-50%);
            padding: 0.5em 1.5em;
            img {
              width: 22px;
            }
          }
        }
      }
    }
  }
  .products-info {
    text-align: left;
    margin-bottom: 1em;
    color: $dark-grey;
    font-size: 1.1em;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 2em;
    }
    .pill {
      font-size: 0.8em;
    }
  }
  .filter-container {
    .filter {
      margin-bottom: 1.5em;
      border-bottom: 1px solid $grey;
      &:last-child {
        border: none;
      }
      .checkbox {
        label {
          padding-left: 2em;
        }
        input[type=checkbox] {
          margin-left: -2em;
        }
      }
      .title {
        font-size: 1.3em;
        color: $light-red;
        display: flex;
        align-items: center;
        .pill {
          background-color: $dark-grey;
          color: #FFF;
          font-size: 0.6em;
          padding: 0.1em 0.4em;
          border-radius: 2px;
          margin: 0 0.5em;
        }
        @media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
          font-size: 1.1em;
        }
      }
      ul {
        padding-left: 0.5em;
        list-style: none;
        margin-bottom: 2em;
        margin-top: 1em;
        li:hover {
          color: $light-red;
        }
      }
    }
  }
  // @media (max-width: $screen-xs-max) {
  //   .products-info {
  //     display: flex;
  //     align-items: center;
  //     justify-content: space-between;
  //     margin-bottom: 2em;
  //   }
  //   // .filter-container {
  //   //   position: absolute;
  //   //   z-index: 100;
  //   //   background: white;
  //   //   padding: 1em;
  //   //   padding-right: 5em;
  //   //   top: 0;
  //   //   box-shadow: 0 2px 2px #E2E2E2;
  //   // }
  // }

  @media (min-width: $screen-md-min) {
    .filter-container {
      width: 20%;
    }
  }
}
