#tra-history {
  &.page-info {
    .content {
      p {
        line-height: 1.5em;
        margin-bottom: 1em;
      }
    }
  }
  .images {
    div[class^="col"]{
      padding-left:5px; padding-right:5px;
    }
    img {
      margin-bottom: 10px;
    }
  }
}