.paginations {
  ul {
    list-style: none;
    padding: 0;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-items: baseline;
    li {
      padding: 0 0.5em;
      margin: 0 0.3em;
      display: inline-block;
      &.selected {
        background: $light-black;
        border-radius: 4px;
        a {
          color: white;
        }
      }
      span, a {
        color: $light-black;
      }
    }
  }
}
