#product-show {
  margin-top: 3em;
  margin-bottom: 3em;
  .breadcrumb {

  }
  .images {
    img {
      width: 100%;
      border: 2px solid $grey;
    }
    .display-image {
      margin-bottom: 0.5em;
    }
    .image-thumbnails {
      display: flex;
      flex-wrap: wrap;
      div {
        cursor: pointer;
        width: 20%;
        padding-right: 0.5em;
        margin-bottom: 0.5em;
        &:nth-child(5n) {
          padding-right: 0;
        }
        img {
          max-height: 90px;
          @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            max-height: 72px;
          }
          &.selected {
            border: 2px solid $light-red;
          }
        }
      }
    }
  }
  .info {
    line-height: 2.5em;
    .title {
      display: flex;
      font-size: 2em;
      flex-wrap: wrap;
      margin-bottom: 0;
      .name {
        padding-right: 0.5em;
      }
      .id {
        font-size: 0.5em;
        background-color: $grey;
        padding: 0.2em 0.4em;
        border-radius: 2px;
        color: $light-black;
      }
    }
    .category-car {
      & > span {
        display: inline-flex;
        align-items: center;
        & > span, a {
          margin-right: 0.3em;
          // color: $dark-grey;
          color: $text-black;
        }
      }
      .category {
        margin-right: 1em;
      }
      [class^="icon-"], [class*=" icon-"] {
        font-size: 1.5em;
        color: $black;
      }
    }
    hr {
      margin: 0;
    } 
    .details {
      font-size: 1.2em;
      color: $light-black;
      margin: 2em 0;
    }
    blockquote {
      font-size: 1.15em;
      margin: 0;
      padding: 0 1em;
      line-height: 2em;
      margin-top: 1em;
      margin-bottom: 1em;
      p {
        margin: 0;
        .id {
          background-color: $grey;
          padding: 0.2em 0.4em;
          border-radius: 2px;
          color: $light-black;
        }
      }
    }
    .remark {
      line-height: 2em;
      p {
        margin: 0;
        &:first-child {
          color: $dark-grey;
        }
      }
    }
  }
  .order {
    margin-top: 1.5em;
    text-align: center;
    .in-cart-msg {
      color: $light-red;
    }
    [class^="icon-"], [class*=" icon-"] {
      font-size: 1.5em;
      color: white;
      margin-left: 0.2em;
    }
    .btn-order {
      background-color: $green;
      color: #FFF;
      padding: 0.8em 3em;
      border-bottom: 4px solid $dark-green;
      & > span {
        display: flex;
      }
    }
    #flash-ordered-msg {
      font-size: 1.2em;
      margin-top: 0.2em;
    }
    .spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.8em;
      input[type="number"] {
        -moz-appearance: textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }
        text-align: center;
        width: 4em;
        margin: 0 0.8em;
        padding: 0 0.5em;
        font-size: 1.2em;
      }
      span {
        cursor: pointer;
        padding: 10px;
        border-radius: 5em;
        background-color: #E8E8E8;
      }
    }
  }
}

#recommended-products {
  margin-bottom: 5em;
  .header {
    font-size: 1.4em;
  }
  hr {
    margin-top: 0.5em;
    border-top-width: 2px;
  }
  .product-container {
    @media (max-width: $screen-sm-max) {
      .product:nth-child(n+4) {
        display: none;
      }
    }
    .product {
      img {
        margin-bottom: 0.5em;
      }
      a.name {
        margin: 0;
        color: $light-red;
      }
      .details {
        font-size: 0.8em;
        p {
          color: $dark-grey;
          margin: 0;
        }
      }
    }
  }
}
