@font-face {
  font-family: 'icomoon';
  src: url('../fonts/iconmoon/icomoon.eot?jkh137');
  src: url('../fonts/iconmoon/icomoon.eot?jkh137#iefix') format('embedded-opentype'),
       url('../fonts/iconmoon/icomoon.ttf?jkh137') format('truetype'),
       url('../fonts/iconmoon/icomoon.woff?jkh137') format('woff'),
       url('../fonts/iconmoon/icomoon.svg?jkh137#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-cart:before {
    content: "\e900";
}
.icon-gear:before {
    content: "\e901";
}
.icon-truck:before {
    content: "\e902";
}
.icon-like:before {
    content: "\e903";
}
.icon-money-bag:before {
    content: "\e904";
}
.icon-cup:before {
    content: "\e905";
}

