#hero {
  height: 360px;
  background-color: $light-grey;
  height: 600px;
  background-image: url(/assets/images/home.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .container {
    position: absolute;
    width: 100%;
    bottom: 8em;
    .row {
      margin-bottom: 2em;
      .brand {
        font-size: 65px;
        font-weight: bold;
        font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
        text-align: center;
        color: $bright-red;
        line-height: 1em;
      }
      .text {
        text-align: center;
        font-size: 22px;
        letter-spacing: 2px;
        color: white;
      }
    }
    .btn-container {
      text-align: center;
      .btn {
        background-color: #FFFFFF;
        border: 0;
        font-size: 1.2em;
        padding: 0.5em 1em;
        color: $light-red;
        border-radius: 4px;
        border-bottom: 2px solid $dark-grey;
      }
    }
    .img-container {
      text-align: center;
      img {
        width: 110px;
        margin-top: 1em;
      }
    }
  }
}

#leading {
  margin-bottom: 3em;
  .text {
    font-size: 1.4em;
    margin-top: 3em;
    margin-bottom: 3em;
    line-height: 2em;
    // color: $light-black;
    color: $light-black;
    p {
      width: 80%;
      display: inline-block;
    }
  }
  .shop-images {
    margin-bottom: 5em;
    div[class^="col"]{
      padding-left: 0px;
      padding-right: 0px;
    }
    img {
      margin-bottom: 10px;
    }
    .bg-image {
      height: 230px;
      background-size: cover;
      background-position: center;
      border: 3px solid white;
      @media (max-width: $screen-xs-max) {
        border-left: none;
        border-right: none;
        height: 300px;
      }
    }
    .main-img {
      height: 460px;
      @media (max-width: $screen-xs-max) {
        height: 350px;
      }
    }
    .inner {
      margin: 0;
    }
  }
  .icon {
    font-size: 3em;
    color: $bright-red;
    margin-top: 10px;
    margin-right: 1.5em;
  }
  .hilight {
    display: flex;
    padding: 0 3em;
    margin-bottom: 2em;
    .title {
      font-size: 1.2em;
      margin-bottom: 0.5em;
    }
    .content {
      color: $dark-grey;
    }
  }
}

.section-title {
  text-align: center;
  font-size: 1.8em;
  color: $light-red;
  margin-bottom: 1em;
}

#home-products {
  img {
    width: 100%;
  }
  .bg-blue {
    background-color: $light-blue;
  }
  .bg-dark-blue {
    background-color: $dark-blue;
  }
  .product-container {
    padding-bottom: 3em;
    .content {
      padding-left: 2em;
      padding-top: 2em;
    }
    .category {
      font-family: "butlerregular","Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 2.3em;
      text-align: center;
      margin: 1.5em 0;
    }
    .product {
      padding: 0.5em;
      @media (max-width: $screen-xs-max) {
        margin-bottom: 2em;
      }
      .details {
        text-align: center;
        .header {
          color: $light-red;
          font-size: 1.2em;
          margin-top: 1em;
          margin-bottom: 0.5em;
        }
        .info {
          font-size: 1.1em;
        }
        .part-details {
          color: $dark-grey;
          display: inline-block;
          border-top: 1px solid $dark-grey;
          margin-top: 1em;
          .icon-truck {
            font-size: 2em;
          }
          .strong {
            color: $black;
          }
          div {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 1em;
            .icon-truck {
              margin-left: 0.5em;
            }
            a {
              color: inherit;
            }
            span {
              margin: 0 0.2em;
            }
          }
        }
      }
      img {
        box-shadow: 0 2px 2px $dark-grey;
        border-radius: 5px;
      }
      @media (min-width: $screen-sm-min) {
        img {
          // width: 95%;
        }
      }
    }
  }

  .text-center {
    margin-top: 1em;
    .btn {
      color: white;
      background-color: $light-red;
      padding: 0.8em 1.5em;
      font-size: 1.1em;
    }
  }
}

#car-manufacturers {
  margin: 6em 0;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 3em;
  }
  .car-images {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 2em;
    img {
      padding: 0 1em;
    }
    .small {
      width: 15%;
    }
    .medium {
      width: 18%;
    }
    .big {
      width: 21%;
    }
    @media (max-width: $screen-xs-max) {
      .small {
        width: 22%;
      }
      .medium {
        width: 25%;
      }
      .big {
        width: 28%;
      }
    }
  }
  .brands {
    display: flex;
    justify-content: space-around;
    @media (max-width: $screen-xs-max) {
      line-height: 2;
      display: block;
    }
    div {
      text-align: center;
    }
    .red {
      color: $light-red;
      font-size: 1.5em;
    }
    a {
      color: $dark-grey;
      font-size: 1.5em;
      letter-spacing: 2px;
      padding-bottom: 3px;
      &:hover {
        border-bottom: 2px solid $light-red;
      }
    }
  }
}

#map {
  padding-top: 4em;
  padding-bottom: 4em;
  background-color: $light-grey;
  .office {
    font-size: 1.2em;
    margin-bottom: 1em;
    .address {
      font-size: 1em;
      color: $light-black;
    }
    span {
      color: $light-black;
      margin-right: 0.8em;
    }
  }
  iframe {
    width: 100%;
  }
  @media (max-width: 414px) {
    .section-title {
      font-size: 2em !important;
    }
  }
}

.back-to-top {
  margin: 2em 0;
  text-align: center;
  font-size: 1.2em;
  a {
    color: $light-red;
  }
}

.mobile-search {
  position: absolute;
  z-index: 5;
  width: 100%;
  margin-top: 2em;
}
.red {
  color: red;
}
.flash-container {
  position: absolute;
  z-index: 10;
  width: 100%;
  margin-top: 2em;
}
