header {
  position: relative;
  ul {
    list-style: none;
    display: inline;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      &.user {
        a {
          text-decoration: underline;
        }
      }
    }
  }
  #top-bar {
    color: white;
    background-color: $black;
    font-size: 0.9em;
    .row {
      display: flex;
      align-items: center;
    }
    ul {
      li {
        margin-right: 0.8em;
      }
    }
    img {
      height: 26px;
      margin-right: 0.5em;
    }
    a {
      color: white;
    }
    .non-link {
      display: inline;
    }
    .socials {
      margin-right: 1.5em;
    }
    .contact-info {
      img {
        height: 22px;
      }
    }
    .sign-in {
      color: black;
      font-weight: bold;
      &:hover {
        color: white;
      }
    }
  }

  #locale-menu {
    img {
      padding: 7px 10px;
      height: 35px;
      background-color: #5B5B5B;
    }
  }
  #site-logo {
    height: 52px;
  }
  nav {
    box-shadow: 0 2px 2px $grey;
    margin-bottom: 2px;
    .row {
      display: flex;
      align-items: center;
    }
    a {
      color: $text-black;
      font-size: 1.2em;
      &:link, &:hover, &:visited, &:active {
        color: $text-black;
      }
      &:hover {
        color: $light-red;
      }
    }
    ul {
      margin-left: 2em;
      li {
        margin: 0 1em;
        .glyphicon {
          color: $red;
          font-size: 0.6em;
          margin-left: 0.7em;
        }
      }
    }
    .nav-menus {
      display: flex;
      align-items: center;
      height: 70px;
    }
    .dropdown-menus {
      position: relative;
      display: inline-block;
      padding: 2em 0;
      > a {
        padding: 1em 0;
      }
    }
    form {
      margin: 0;
      width: 15em;
      margin-left: 1.5em;
      .input-group {
        display: flex;
      }
      .btn {
        border: none;
        background-color: white;
        .glyphicon {
          font-size: 1.2em;
        }
        &:hover {
          color: $red;
        }
        &:active, &:focus, &:active:focus {
          outline: none;
          box-shadow: none;
        }
      }
      #top-search {
        border-radius: 4px;
        background-color: $light-grey;
      }
    }
    #cart-items {
      display: inline;
      font-size: 0.7em;
      background: $light-red;
      color: white;
      padding: 2px 6px;
      border-radius: 4px;
      margin-bottom: -5px;
    }
    #top-shopping-cart {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .cart {
      height: 32px;
      display: block;
    }
    .right-menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    #mobile-menu {
      &:hover {
        color: $text-black;
      }
      &.active {
        color: $light-red;
      }
      span {
        font-size: 2em;
        margin-left: 0.5em;
      }
    }
  }

  #mobile-menus {
    display: none;
    position: absolute;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 4px $medium-grey;
    z-index: 150;
    li {
      display: block;
      padding: 1em 0;
      border-bottom: 2px solid $light-grey;
      &:last-child {
        border: none;
      }
      a {
        font-size: 1.5em;
        color: $text-black;
        letter-spacing: 2px;
      }
    }
  }
  // &.fixed {
  //   nav {
  //     position: fixed;
  //     top: 0;
  //     width: 100%;
  //     background: white;
  //     z-index: 200;
  //   }
  //   #mobile-menus {
  //     position: fixed;
  //     top: 70px;
  //   }
  // }
}
