// body[lang='th'] {
body {

  @mixin th($font-size) {
    font-family: 'kanitlight', sans-serif !important;
    font-size: $font-size;
  }
  @include th(14px);

  .th {
    @include th(18px);
  }

  .th-big {
    font-size: 3.2rem !important;
    letter-spacing: 1px !important;
  }

  .th-medium {
    font-size: 2.3rem !important;
  }

  .th-small {
    font-size: 1.4rem !important;
  }

  .nav-menus > ul > li > a {
    @include th(18px);
    @media (max-width: $screen-md-max) {
      font-size: 16px;
    }
  }
}
