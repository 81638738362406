#products-search {
  padding-top: 0;
  padding-bottom: 5em;
  h3 {
    margin-top: 2em;
    margin-bottom: 0.5em;
  }
  h4 {
    color: $light-black;
    span {
      background: $grey;
      padding: 0.2em 0.5em;
      border-radius: 4px;
    }
  }
  .product-list {
    margin-top: 2em;
  }
  &.no-products {
    padding-bottom: 0;
    margin-bottom: 2em;
  }
  #no-product-guide {
    margin-top: 3em;
    margin-bottom: 2em;
    .btn {
      color: white;
      background-color: $light-red;
      padding: 0.8em 1.5em;
      font-size: 1.1em;
      margin-top: 1em;
    }
  }
}
