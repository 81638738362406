#mobile-search-form {
  .input-group {
    display: flex;
    margin-bottom: 1.5em;
    input {
      font-size: 1.5em;
      padding: 1em 2em 1em 0.5em;
    }
    button {
      font-size: 1.5em;
      background: none;
    }
  }
}
